import { produce } from 'immer';

export const createAction = (type) => {
  const actionCreator = (payload) => ({ type, payload });

  actionCreator.toString = () => type;
  actionCreator.type = type;

  return actionCreator;
};

export const createAsyncAction = (type) => {
  const requestType = `${type}_REQUEST`;
  const successType = `${type}_SUCCESS`;
  const failureType = `${type}_FAILURE`;

  const request = createAction(requestType);
  const success = createAction(successType);
  const failure = createAction(failureType);

  return {
    request,
    success,
    failure,
  };
};

export const createReducer = (actionPrefix, initialState, reducerMap) => (
  state = initialState,
  action
) => {
  return produce(state, (draftState) => {
    if (actionPrefix && action.type.startsWith(actionPrefix)) {
      setAsyncProps({ state: draftState, action });
    }

    const reducerFn = reducerMap[action.type];

    if (reducerFn) {
      reducerFn({ state: draftState, action });
    }
  });
};

export const initialAsyncState = { fetching: true, error: null };

export const setAsyncProps = ({ state, action }) => {
  if (action.type.endsWith('REQUEST')) {
    state.fetching = true;
    state.error = null;
  }
  if (action.type.endsWith('SUCCESS')) {
    state.fetching = false;
    state.error = null;
  }
  if (action.type.endsWith('FAILURE')) {
    state.fetching = false;
    state.error = action.payload;
  }
};
