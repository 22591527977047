import React from 'react';

import AntdCard from 'antd/es/card';
import 'antd/es/card/style/index.css';

export const Card = ({ children, className, onClick, style: css, ...rest }) => (
  <AntdCard
    className={className}
    onClick={onClick}
    style={{
      margin: '20px auto',
      borderRadius: 20,
      paddingTop: 20,
      ...css,
    }}
    {...rest}
  >
    {children}
  </AntdCard>
);

Card.Grid = AntdCard.Grid;
Card.Meta = AntdCard.Meta;
