import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import { Typography } from 'antd';
import { CountUp } from 'countup.js';

export const Countup = ({ number }) => {
  const spanRef = useRef(null);

  useEffect(() => {
    if (!_.isNil(number)) {
      const countup = new CountUp(spanRef.current, Number(number));

      countup.start();
    }
  }, [number]);

  return (
    <Typography.Title style={{ marginBottom: 0 }}>
      <span ref={spanRef}>{number}</span>
    </Typography.Title>
  );
};
