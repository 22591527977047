import React from 'react';

import AntdTabs from 'antd/es/tabs';
import 'antd/es/tabs/style/index.css';

import './Tabs.scss';

export const Tabs = ({ children, ...rest }) => (
  <AntdTabs className="tabs-container" type="card" {...rest}>
    {children}
  </AntdTabs>
);

Tabs.TabPane = AntdTabs.TabPane;
